<template>
    <section>
        <sm_header title='酒店结算' :smHleftEvent='closeWebview'></sm_header>
        <venue-card :demandData="demandData"></venue-card>
        <div class="headTips" :class="isShowTips ? 'active' : ''" v-show="headTips.length">
            <p class="tipsTitle" @click="isShowTips = !isShowTips">
              注意事项
              <span>
                <svg class="icon" aria-hidden="true" style="width: .15rem;height: .15rem">
                  <use :xlink:href="isShowTips ? '#iconarrow-up1' : '#iconarrow-down'"></use>
                </svg>
              </span>
            </p>
            <p v-for="(item, index) in headTips" :key="index"> {{ item }} </p>
        </div>
      <div class="quotedPrice">
        <van-field label="结算总金额" placeholder="请输入结算总金额" type="number" :required='true' :readonly="!showInput" v-model="demandData.settle"/>
      </div>
        <div class="quotedPrice disabledPrice">
            <van-field label="报价" :readonly="true" v-model="demandData.quote"/>
        </div>
        <div class="quotedPrice disabledPrice">
            <van-field :label="updateAmountTxt" :readonly="true" v-model="updateAmount"/>
        </div>
        <div v-for="(item, index) in demandData.itsExpenses" :key="index">
            <item-card :itemInfo="item" @costItemDetail="costItemDetail"></item-card>
            <div style="width: 100%;height: .08rem;background-color: #f8f8f8"></div>
        </div>

        <div class="fileTitle">
            {{fileName}}
            <FinderFrame
                    :gateway="gateway"
                    :OrderByData="OrderByData"
                    ref="FinderFrame"
                    :actions="actions"
                    :isPreview="true"
                    :h5FilePreview="false"
                    :onlyPreview="!showUpdate"
                    :filterData="uploaderSettings.filterData"
                    :mappingData="uploaderSettings.mappingData"
                    :isMapping="true"
                    :uploadTimeout="120000"
                    @uploadCallback="() => {}"
            />
            <div v-if="showInvoice">
                {{filterData.catalog}}
                <FinderFrame
                        :gateway="gateway"
                        :OrderByData="OrderByData"
                        ref="FinderFrame"
                        :isPreview="true"
                        :h5FilePreview="false"
                        :onlyPreview="true"
                        :filterData="filterData"
                        :isMapping="true"
                        :uploadTimeout="120000"
                        @uploadCallback="() => {}"
                />
            </div>
        </div>
        <div class="confirmCostFooter" v-if="btnList.length">
            <div class="largeBtn2" v-for="(item, index) in btnList" :key="index" :style="{width: (90 / btnList.length)+ '%'}"
                 @click="onceAgain(item)">{{item.actName}}</div>
        </div>
    </section>
</template>
<script>
    import venueCard from "./components/venueCard";
    import itemCard from "./components/itemCard";
    import {FinderFrame} from "smart-filer-vue";
    // import Prompt from '@/components/prompt/plugin';
    import "smart-filer-vue/lib/index.css";
    import {getCookie} from "tiny-cookie";
    import generalBridge from "@/plugin/generalBridge.js";
    import { Field } from 'vant';
    export default {
        components: {
            itemCard,
            venueCard,
            FinderFrame,
          [Field.name]: Field,
        },
        data () {
            return {
                title: '酒店结算',
                taskId: '',
                showInput: false,
                visible: false,
                isShowTips: true,
                costInnerItem: {},
                btnList: [],
                headTips: [],
                uploaderSettings: {
                    mappingData: {
                        processId: this.$SmartStorage.get('proposalId'),
                        supplierId: this.$SmartStorage.get('supplierId'),
                        relatedType: "bill",
                        catalog: '水单'
                    },
                    filterData: {
                        processId: this.$SmartStorage.get('proposalId'),
                        supplierId: this.$SmartStorage.get('supplierId'),
                        relatedType: ["bill","bill_reback","SupplierSettle","settle"],
                        catalog: '水单'
                    }
                },
                filterData: {
                    processId: this.$SmartStorage.get('proposalId'),
                    supplierId: this.$SmartStorage.get('supplierId'),
                    catalog: "发票"
                },
                demandData: {
                    itsQuoteExpense: []
                },
                gateway: process.env.VUE_APP_GATEWAY,
                OrderByData: {
                    catalog: 'asc',
                    CreatedOn: 'asc'
                },
                actions: [
                    {actionType: "carame", actionLabel: "拍照"},
                    {actionType: "album", actionLabel: "相册", accepts: "image/*"},
                ],
                tenant: getCookie('tenant') || this.$SmartStorage.get("tenant") || this.$SmartStorage.get("tenant_code")
            }
        },
        created () {
            this.queryOrderSupplierSettleExpense()
            this.getTips()
        },
        computed: {
            updateAmount () {
                return  this.demandData.quote-this.demandData.settle >= 0 ?
                    this.currencyFormatter(this.demandData.quote-this.demandData.settle || 0) :
                    this.currencyFormatter((0 - (this.demandData.quote-this.demandData.settle)) || 0)
            },
            updateAmountTxt () {
                return  this.demandData.quote-this.demandData.settle >= 0 ? '省' : '超'
            },
            showInvoice () {
                return  this.demandData.status >= 60
            },
            showUpdate () {
                return [50].includes(this.demandData.status)
            },
            fileName () {
              return !this.showUpdate ? '水单' : '上传水单'
            }
        },
        methods: {
            currencyFormatter(amount, symbol = '￥', digits = 2) {
                return `${symbol}${Number(amount || 0).toFixed(digits)}`
            },
          async queryOrderSupplierSettleExpense () {
            let params = {
              orderId: this.$SmartStorage.get('orderId'),
              proposalId: this.$SmartStorage.get('proposalId'),
              supplierId: this.$SmartStorage.get('supplierId')
            }
            let res = await this.$service.QueryOrderSupplierSettleExpense(params);
            if (res && res.success) {
              this.demandData = res.content || {}
              this.btnList = this.demandData.itsButtons || []
              this.showInput = [50].includes(this.demandData.status)
            }
          },
            closeWebview () {
                this.$router.go(-1)
            },
            costItemDetail (item) {
                this.$SmartStorage.set('costInnerItem', item)
                this.$router.push({ path: "/costItemDetailOrder"});
            },
            onceAgain(btn) { // 二次提示
              this[btn.actCode](btn)
            },
          async goPay(data, url){
            let params = {
              Data: {
                eventData: {
                  proposalId: this.$SmartStorage.get('proposalId'),
                },
                itemData: {},
                tenant_code: this.tenant,
                isH5: this.$SmartStorage.get('isH5'),
                tenant_id: getCookie('tenant_id'),
                tenant: this.tenant,
                token: getCookie('token'),
              }
            }
            let itemData = {
              orderId: this.$SmartStorage.get('orderId'),
              proposalId: this.$SmartStorage.get('proposalId'),
              itemId: this.$SmartStorage.get('supplierId'),
              name: this.demandData.name,
              settle: this.demandData.settle,
              sessionId: this.$SmartStorage.get('sessionId'),
            }

            if (this.$SmartStorage.get('authMobile')) {
              itemData.authmobile = this.$SmartStorage.get('authMobile')
            }
            params.Data.itemData = Object.assign(itemData, data)
            let res = await this.$service.saveCacheData(params)
            if (res && res.success) {
              let ipayURL = `${this.$SmartStorage.get("Uris").Uris["webapp-ipay"]}/${url}?sessionId=${res.content}`
              generalBridge.openWebAppCate(ipayURL)
              this.closeWebview()
            }
          },
          async getTips () {
            let miceInfo = this.$SmartStorage.get("eventData")
            let params = {
              collection: "cfg-ivenue-tips",
              filter: {
                tenantCode: this.tenant,
                origin: 'settlePrice',
                typeDictVal: {"$in":[ miceInfo?.typeDictTxt || "*","*"]},
              }
            }
            let res = await this.$service.getsettings(params)
            if (res && res.success) {
              this.headTips = res.content ? res.content.content.tips : []
            }
          },
          cancel () {
            this.$dialog.confirm({
              message: '取消酒店品类需求，将进入损失费流程，会议可能包含损失费，请确认是否继续',
            }).then(async () => {
                  let params = {
                    orderId: this.$SmartStorage.get('orderId'),
                    proposalId: this.$SmartStorage.get('proposalId'),
                    supplierId: this.$SmartStorage.get('supplierId'),
                    taskId: this.$SmartStorage.get('taskId'),
                    LossStatus: 1
                  }
                  let res = await this.$service.post(this.api.updateLossStatus, params)
                  if (res.success) {
                    this.closeWebview();
                  }
                })
                .catch(() => {
                  // on cancel
                });
          },
          async confirmSettle () {
            if(!this.demandData.settle) {
              this.$toast('请输入结算金额')
              return
            }
            let params = {
              orderId: this.$SmartStorage.get('orderId'),
              proposalId: this.$SmartStorage.get('proposalId'),
              supplierId: this.$SmartStorage.get('supplierId'),
              taskId: this.$SmartStorage.get('taskId'),
              actionId: this.$SmartStorage.get('actionId'),
              settle: this.demandData.settle,
              OperationType: 'acceptSettle',
            }

            let headers = {}
            if (this.$SmartStorage.get('authMobile')) {
              headers = {
                headers: {"authmobile": this.$SmartStorage.get('authMobile')}
              }
            }
            let res = await this.$service.confirmSupplierSettle(params, headers);
            if (res && res.success) {
              this.SaveMiceAssistantInfo(res)
            }
          },
          async SaveMiceAssistantInfo (result) {
            let params = {
              miceId: this.$SmartStorage.get('proposalId'),
              orderId: this.$SmartStorage.get('orderId'),
              itemId: null,
              authmobile: this.$SmartStorage.get('authMobile'),
              supplierId: this.$SmartStorage.get('supplierId'),
            }
            let res = await this.$service.SaveMiceAssistantInfo(params)
            if (res && res.success) {
              if ([20, 30].includes(result.content.payMode) && this.demandData.isOffline) {
                this.goPay(result.content, [20].includes(result.content.payMode) ? 'cashier' : 'paySelect')
                this.closeWebview()
              } else {
                this.$toast('确认结算成功')
                this.$router.back()
              }
            }
          },
          async toPay () {
            let params = {
              orderId: this.$SmartStorage.get('orderId'),
              miceId: this.$SmartStorage.get('proposalId'),
              supplierId: this.$SmartStorage.get('supplierId'),
            }
            let res = await this.$service.QueryEOrderSupplierPayInfo(params)
            if (res && res.success) {
              this.goPay(Object.assign(res.content, {orderId: this.$SmartStorage.get('orderId')}), [20].includes(res.content.payMode) ? 'cashier' : 'paySelect')
              this.closeWebview()
            }
          },
          uploadInvoice () {
            this.goPay({origin: "Venue", orderId: this.$SmartStorage.get('orderId')}, 'uploadInvoice')
            this.closeWebview()
          }
        }
    }
</script>
<style lang="scss" scoped>
.quotedPriceHead {
  font-size: .25rem;
  text-align: left;
  padding: 0.1rem 0.2rem;
  background: var(--themeColor);
  color: #ffffff;
  font-weight: bold;
}
.headTips {
  background: #F5F5F5;
  margin: .1rem .1rem 0;
  padding: .15rem .18rem;
  font-size: .12rem;
  border-radius: .04rem;
  max-height: 0.2rem;
  overflow: hidden;
  transition: max-height .5s ease;
  p {
    font-size: .12rem;
    text-align: left;
    color: #909399;
  }
  .tipsTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: .15rem;
    color: #13161B;
    margin-bottom: 0.2rem;
  }
}
.headTips.active{
  max-height: 2rem;
}
.fileTitle {
    text-align: left;
    font-size: .16rem;
    font-weight: bold;
    color: #000;
    padding:.1rem;
    position: relative;
  margin-top: .1rem;
}
.confirmCostFooter {
    position: fixed;
    bottom: 0;
    padding: 0.2rem 0.1rem 0.1rem 0.1rem;
    width: calc(100% - 0.2rem);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    background: #fff;
    .largeBtn2 {
        padding: 0.1rem 0;
        border-radius: 0.04rem;
        font-size: .17rem;
        background: #FFFFFF;
        border: 1px solid #E6E7E9;
        font-weight: 500;
        color: #90939A;
        &:last-child{
            background: var(--themeColor);
            border: 1px solid var(--themeColor);
            color: #FFFFFF;
        }
    }
}
</style>
<style lang="scss">
.confirmCostHead {
  .header-bg {
    opacity: 1 !important;
  }
}
.quotedPrice {
  margin: .1rem .1rem .2rem;
  border-radius: .04rem;
  .van-cell {
    background: #F5F5F5;
    .van-field__control {
      text-align: right;
    }
  }
}
</style>
