import {removeCookie, getCookie} from 'tiny-cookie'
import * as tinyCookie from "tiny-cookie";
import {SmartStorage} from 'smart-core-util'
import { httpService } from '../service/httpService'
import iJsBridge from 'smart-javascript-bridge'
import { Toast } from 'vant';
export default class generalBridge {
    static propy () {
        if (['browser','h5', 'native'].includes(getCookie("container") || SmartStorage.get("container"))) {
            const container = getCookie("container")  || SmartStorage.get("container");
            switch (container) {
                case "browser":
                    generalBridge._browserBack()
                    break;
                case "h5":
                    generalBridge._h5Back()
                    break;
                case "native":
                default:
                    generalBridge.closeAppWithReload.call(this);
            }
        } else if (SmartStorage.get("isH5")) {
            generalBridge._h5Back()
        } else {
            generalBridge.closeAppWithReload.call(this);
        }
    }

    static async getUrl () {
        const cacheData = {
            supplierId: SmartStorage.get("supplierId"),
            userId: SmartStorage.get("userId"),
            role_codes: SmartStorage.get("role_codes"),
            directory_id: SmartStorage.get("directory_id"),
            tenant_code: SmartStorage.get("tenant_code"),
            token: SmartStorage.get("token"),
            container: SmartStorage.get("container"),
            tenant_id: SmartStorage.get("tenant_id"),
            platform: SmartStorage.get("platform"),
        };
        const params = {
            Data: cacheData
        };
        let res = await httpService.saveCacheData(params)
        let ievent = '';
        if (res && res.success) {
            let miceId = SmartStorage.get("proposalId");
            let requireId = SmartStorage.get("eventData").requireId;
            const eventData = SmartStorage.get("eventData");
            const ieventUrl = SmartStorage.get("Uris").Uris["webapp-ievent"];
            let fromOtherSite = SmartStorage.get('fromOtherSite')
            switch (getCookie('tenant') || getCookie('tenant_code')) {
                case 'msd':
                    ievent =SmartStorage.get('isAll')?ieventUrl+'/opHome?sessionId='+res.content:
                        `${ieventUrl}/opConfirmPrice?businessId=${miceId}&processId=${miceId}&sessionId=${res.content}`
                    break;
                case 'crossevent':
                case 'pfizer':
                    if(['ievent'].includes(fromOtherSite)){
                        ievent = `${ieventUrl}/application?sessionId=${res.content}`
                    } else {
                        ievent = `${ieventUrl}/eventDetail?miceId=${eventData.miceId}&sessionId=${res.content}`
                    }
                    break;
                default:
                    if(['ievent'].includes(fromOtherSite)){
                        ievent = `${ieventUrl}/Home/More?sessionId=${res.content}`
                    } else {
                        ievent = `${ieventUrl}/eventDetail?requireId=${requireId}&miceId=${miceId}&sessionId=${res.content}&rad=${res.content}`
                    }
                    break;
            }
            SmartStorage.remove('fromOtherSite')
            SmartStorage.remove('proposalId')
            SmartStorage.remove('eventData')
            return ievent;
        }
        return ievent;
    }
    static async _browserBack () {
        let url= await this.getUrl()
        SmartStorage.remove("proposalId")
        removeCookie('eventData')
        window.open(url);
    }

    static async  _h5Back () {
        let url=await this.getUrl()
        SmartStorage.remove("proposalId")
        removeCookie('eventData')
        window.open(url, "_self");
    }
    static closeAppWithReload () {
        let IJsBridge = new iJsBridge({ alert: Toast });
        let sendData = JSON.stringify({handle: "reload_event_detail"});
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler("SmGeneral.closeWebSite", sendData);
        } else {
            IJsBridge.call({ method: 'SMGeneral.setResultToNative', postData: sendData });
            IJsBridge.close();
        }
        SmartStorage.remove("proposalId")
        removeCookie('eventData')
    }
    static openWebAppCate (url, _callback = null) {
        if (['browser','h5', 'native'].includes(getCookie("container"))) {
            const container = getCookie("container");
            switch (container) {
                case "browser":
                    ["webapp-iaccount", "webapp-ihelper"].includes(url) ? this._h5(url) :
                        this._browser(url);
                    break;
                case "h5":
                    this._h5(url);
                    break;
                case "native":
                    this.openNewWebSite(url, _callback);
            }
        } else if (SmartStorage.get("isH5")) {
            this._h5(url);
        } else {
            this.openNewWebSite(url, _callback);
        }
    }
    // 浏览器模拟
    static _browser(hostUrl) {
        window.open(hostUrl);
    }
    // h5模式
    static _h5(hostUrl) {
        window.open(hostUrl, "_self");
    }
    static async openNewWebSite (url, _callback = null) {
        let IJsBridge = new iJsBridge({ alert: Toast });
        let _this= this
        if (window.flutter_inappwebview) {
            let params = {
                "url": url,
                "postData": {},
                "cookies": tinyCookie.getAllCookies()
            }
            let result = await window.flutter_inappwebview.callHandler("SmGeneral.openNewWebSite", JSON.stringify(params));
            if (_callback) {
                _callback(result);
            } else {
                _this.broadcast(result)
            }
        } else {
            let postData = {
                loadUrl: url,
                nativeLocationHelper: true,
                cookieData: tinyCookie.getAllCookies()
            }
            IJsBridge.call({ method: 'SMGeneral.openNewWebSiteForResult', postData: postData ,callback: _callback ?  _callback : _this.broadcast,});
        }
    }
    //关闭站点回调
    static broadcast() {
        // this.$router.back()
    }
}
