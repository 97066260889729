<template>
    <section>
        <div class="itemInfoBox">
            <div class="itemTitle">
                {{demandData.name}}
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        name: 'itemCard',
        props: {
          demandData: {
            type: Object,
            default(){
              return {}
            }
          }
        },
        data () {
            return {
            }
        },
        created() {
        },
        computed: {
        },
        methods: {
            currencyFormatter(amount, symbol = '￥', digits = 2) {
                return `${symbol}${Number(amount || 0).toFixed(digits)}`
            },
        }
    }
</script>
<style lang="scss" scoped>
    @import "venueCard";
</style>
